import {
  Form,
  Input,
  Checkbox,
  Button,
  Space,
  Message,
} from '@arco-design/web-react';
import { connect } from 'react-redux';
import { FormInstance } from '@arco-design/web-react/es/Form';
import { IconLock, IconUser } from '@arco-design/web-react/icon';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useStorage from '@/utils/useStorage';
import useLocale from '@/utils/useLocale';
import locale from './locale';
import styles from './style/index.module.less';
import { getProfile, login } from './service';
import { GlobalState } from '@/store';

interface LoginFormProps {
  dispatchUserInfo?: (payload) => {
    type: string;
    payload: GlobalState['userInfo'];
  };
}

const LoginForm: React.FC<LoginFormProps> = ({ dispatchUserInfo }) => {
  const formRef = useRef<FormInstance>();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [loginParams, setLoginParams, removeLoginParams] =
    useStorage('loginParams');

  const t = useLocale(locale);
  const history = useHistory();
  const [rememberPassword, setRememberPassword] = useState(!!loginParams);

  const afterLoginSuccess = (params) => {
    // 记住密码
    if (rememberPassword) {
      setLoginParams(JSON.stringify(params));
    } else {
      removeLoginParams();
    }
    // 记录登录状态
    localStorage.setItem('userStatus', 'login');
    // 跳转首页
    // window.location.href = '/';
    history.replace('/');
  };

  /**
   * 登录
   * @param params 登录参数
   */
  const handleLogin = (params) => {
    setErrorMessage('');
    setLoading(true);
    login(params)
      .then((res) => {
        const { data } = res;
        if (data?.token) {
          Message.success('登录成功');
          afterLoginSuccess(params);
          //记录TOKEN
          localStorage.setItem('token', data?.token || '');
          dispatchUserInfo({ userLoading: true });
          return getProfile();
        }
      })
      .then((res) => {
        console.log(res);
        dispatchUserInfo({ userInfo: res.data, userLoading: false });
      })
      .catch((err) => {
        console.log(err);
        Message.warning(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmitClick = () => {
    formRef.current.validate().then((values) => {
      handleLogin(values);
    });
  };

  // 读取 localStorage，设置初始值
  useEffect(() => {
    const rememberPassword = !!loginParams;
    setRememberPassword(rememberPassword);
    if (formRef.current && rememberPassword) {
      const parseParams = JSON.parse(loginParams);
      formRef.current.setFieldsValue(parseParams);
    }
  }, [loginParams]);

  return (
    <div className={styles['login-form-wrapper']}>
      <div className={styles['login-form-title']}>{t['login.form.title']}</div>
      <div className={styles['login-form-error-msg']}>{errorMessage}</div>
      <Form
        className={styles['login-form']}
        layout="vertical"
        ref={formRef}
        // initialValues={loginParams}
      >
        <Form.Item
          field="username"
          rules={[{ required: true, message: t['login.form.userName.errMsg'] }]}
        >
          <Input
            prefix={<IconUser />}
            placeholder="请输入账号"
            onPressEnter={onSubmitClick}
          />
        </Form.Item>
        <Form.Item
          field="password"
          rules={[{ required: true, message: t['login.form.password.errMsg'] }]}
        >
          <Input.Password
            prefix={<IconLock />}
            placeholder="请输入账号密码"
            onPressEnter={onSubmitClick}
          />
        </Form.Item>
        <Space size={16} direction="vertical">
          <div className={styles['login-form-password-actions']}>
            <Checkbox checked={rememberPassword} onChange={setRememberPassword}>
              {t['login.form.rememberPassword']}
            </Checkbox>
          </div>
          <Button type="primary" long onClick={onSubmitClick} loading={loading}>
            {t['login.form.login']}
          </Button>
        </Space>
      </Form>
    </div>
  );
};
const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});

const mapDispatchToProps = {
  dispatchUserInfo: (userInfo) => ({
    type: 'update-userInfo',
    payload: userInfo,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
