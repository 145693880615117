import { Message } from '@arco-design/web-react';
import serverConfig from './config';

interface IResponse {
  message?: string;
  data?;
  error?: Error;
}

interface IRequestOptions {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  headers?: { [key: string]: string };
  // 其他已知的字段
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any; // 索引签名
}

function request(url, options: IRequestOptions = {}): Promise<IResponse> {
  const { data, params, ...fetchOptions } = options;

  url = serverConfig.API_BASE_URL + url;

  if (params) {
    const queryParams = new URLSearchParams(params);
    url = `${url}?${queryParams}`;
  }

  // 设置请求头部
  const headers = {
    Authorization: `bearer ${localStorage.getItem('token')}`, //认证信息
  };
  // if (data instanceof FormData) {
  //   headers['Content-Type'] = 'multipart/form-data';
  // }
  if (data && !(data instanceof FormData)) {
    headers['Content-Type'] = 'application/json';
  }
  // 合并options
  const requestConfig = {
    ...fetchOptions,
    headers: {
      ...headers,
      ...fetchOptions.headers,
    },
    body: data
      ? data instanceof FormData
        ? data
        : JSON.stringify(data)
      : undefined,
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestConfig)
      .then((response) => {
        const { status } = response;
        if (status === 401) {
          Message.warning('登录失效！请重新登录！');
          localStorage.setItem('userStatus', 'logout');
          localStorage.removeItem('token');
          window.location.href = './login';
        }
        return response.json().then((data) => ({ status, data }));
      })
      .then((res) => {
        console.log('请求返回：', res);
        const { data, status } = res;
        if (status !== 200)
          throw new Error(
            typeof data?.message === 'string' && data.message !== ''
              ? data.message
              : '请求失败！请稍后再试！'
          );
        resolve(data);
      })
      .catch((error) => reject(error));
  });
}

export default request;
