import React, { useContext, useEffect } from 'react';
import {
  Tooltip,
  Avatar,
  Select,
  Dropdown,
  Menu,
  Message,
  Button,
} from '@arco-design/web-react';
import {
  IconLanguage,
  IconSunFill,
  IconMoonFill,
  IconSettings,
  IconPoweroff,
  IconUser,
} from '@arco-design/web-react/icon';
import { useSelector, useDispatch } from 'react-redux';
import { GlobalState } from '@/store';
import { GlobalContext } from '@/context';
import useLocale from '@/utils/useLocale';
// import Logo from '@/assets/logo.svg';
import Logo from '@/assets/logo.png';
import IconButton from './IconButton';
import Settings from '../Settings';
import styles from './style/index.module.less';
import defaultLocale from '@/locale';
import useStorage from '@/utils/useStorage';
import { generatePermission } from '@/routes';
import { logout } from '@/pages/login/service';

function Navbar({ show }: { show: boolean }) {
  const t = useLocale();
  const userInfo = useSelector((state: GlobalState) => state.userInfo);
  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setUserStatus] = useStorage('userStatus');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [__, ___, removeToken] = useStorage('token');
  const [role] = useStorage('userRole', 'admin');

  const { setLang, lang, theme, setTheme } = useContext(GlobalContext);

  useEffect(() => {
    dispatch({
      type: 'update-userInfo',
      payload: {
        userInfo: {
          ...userInfo,
          permissions: generatePermission(role),
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  if (!show) {
    return (
      <div className={styles['fixed-settings']}>
        <Settings
          trigger={
            <Button icon={<IconSettings />} type="primary" size="large" />
          }
        />
      </div>
    );
  }

  /**
   * 处理登出
   */
  const handleLogout = () => {
    //请求登出
    logout()
      .then(() => {
        Message.success('退出成功！');
        setUserStatus('logout');
        removeToken(); //移除token
        window.location.href = '../login';
      })
      .catch((err) => console.log(err));
  };

  /**
   * 下拉菜单点击
   * @param key 点击的菜单项的key值
   */
  const onMenuItemClick = (key) => {
    if (key === 'logout') {
      handleLogout();
    }
  };

  const droplist = (
    <Menu onClickMenuItem={onMenuItemClick}>
      <Menu.Item key="logout">
        <IconPoweroff className={styles['dropdown-icon']} />
        {t['navbar.logout']}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.navbar}>
      <div className={styles.left}>
        <div className={styles.logo}>
          {/* <Logo /> */}
          <img src={Logo} width={24} />
          <div className={styles['logo-name']}>恒坤游戏平台</div>
        </div>
      </div>
      <ul className={styles.right}>
        {/* <li>
          <Input.Search
            className={styles.round}
            placeholder={t['navbar.search.placeholder']}
          />
        </li> */}
        <li>
          <Select
            triggerElement={<IconButton icon={<IconLanguage />} />}
            options={[
              { label: '中文', value: 'zh-CN' },
              { label: 'English', value: 'en-US', disabled: true },
            ]}
            value={lang}
            triggerProps={{
              autoAlignPopupWidth: false,
              autoAlignPopupMinWidth: true,
              position: 'br',
            }}
            trigger="hover"
            onChange={(value) => {
              setLang(value);
              const nextLang = defaultLocale[value];
              Message.info(`${nextLang['message.lang.tips']}${value}`);
            }}
          />
        </li>
        {/* <li>
          <MessageBox>
            <IconButton icon={<IconNotification />} />
          </MessageBox>
        </li> */}
        <li>
          <Tooltip
            content={
              theme === 'light'
                ? t['settings.navbar.theme.toDark']
                : t['settings.navbar.theme.toLight']
            }
          >
            <IconButton
              icon={theme !== 'dark' ? <IconMoonFill /> : <IconSunFill />}
              onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}
            />
          </Tooltip>
        </li>
        {/* <Settings /> */}
        {userInfo && (
          <li>
            <Dropdown droplist={droplist} position="br">
              <Avatar size={32} style={{ cursor: 'pointer', marginRight: 8 }}>
                {userInfo.avatar ? (
                  <img alt="avatar" src={userInfo.avatar} />
                ) : (
                  <IconUser />
                )}
              </Avatar>
              <span className={styles.username}>{userInfo.username}</span>
            </Dropdown>
          </li>
        )}
      </ul>
    </div>
  );
}

export default Navbar;
